import React from "react";
// import SaveButton from "./Templates/SaveButton";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SelectField,
  DateField,
  EditButton,
  SimpleForm,
  TextInput,
  SelectInput,
  Toolbar,
  SaveButton
} from 'react-admin';

const TemplatesCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={true} />
  </Toolbar>
);

const TemplatesTitle = ({ record }) => {
  return <span>{record ? `${record.code}` : ""}</span>;
};

export const TemplatesEdit = props => (
  <Edit title={<TemplatesTitle />} {...props}>
    <SimpleForm redirect="list">
      <SelectInput source="type" choices={[
        { id: 'EMail', name: 'E-Posta Şablonu' },
        { id: 'SMS', name: 'SMS Şablonu' },
      ]} />
      <TextInput source="code" label="Şablon Kodu" />
      <TextInput source="description" label="Şablon Açıklaması" />
      <TextInput source="bodyHtml" label="HTML İçerik" />
      <TextInput source="bodyText" label="Text İçerik" />
    </SimpleForm>
  </Edit>
);

export const TemplatesCreate = props => (
  <Create title="Şablon Oluşturma" {...props}>
    <SimpleForm toolbar={<TemplatesCreateToolbar />} redirect="list">
      <SelectInput source="type" choices={[
        { id: 'EMail', name: 'E-Posta Şablonu' },
        { id: 'SMS', name: 'SMS Şablonu' },
      ]} />
      <TextInput source="code" label="Şablon Kodu" />
      <TextInput source="description" label="Şablon Açıklaması" />
      <TextInput source="bodyHtml" label="HTML İçerik" />
      <TextInput source="bodyText" label="Text İçerik" />
    </SimpleForm>
  </Create>
);

export const TemplatesList = props => (
  <List title="Şablon Listesi" {...props}>
    <Datagrid redirect="list">
      <SelectField source="type" choices={[
        { id: 'EMail', name: 'E-Posta Şablonu' },
        { id: 'SMS', name: 'SMS Şablonu' },
      ]} />
      <TextField source="code" label="Şablon Kodu" />
      <TextField source="description" label="Şablon Açıklaması" />
      <TextField source="bodyHtml" label="HTML İçerik" />
      <TextField source="bodyText" label="Text İçerik" />
      <DateField source="created" label="Tanımlanma Tarihi" />
      <EditButton label="" />
    </Datagrid>
  </List>
);
