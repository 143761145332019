
import React from "react";
// import SaveButton from "./Templates/SaveButton";
import {
    List,
    Edit,
    Create,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    SimpleForm,
    TextInput,
    SelectInput,
    DisabledInput,
    Toolbar,
    SaveButton
} from 'react-admin';

const ReviewsCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton label="Save" redirect="list" submitOnEnter={true} />
    </Toolbar>
);

const ReviewsTitle = ({ record }) => {
    return <span>{record ? `${record.code}` : ""}</span>;
};

export const ReviewsEdit = props => (
    <Edit title={<ReviewsTitle />} {...props}>
        <SimpleForm redirect="list">
            <SelectInput source="status" choices={[
                { id: 'New', name: 'New' },
                { id: 'Spam', name: 'Spam' },
                { id: 'Ignored', name: 'Ignored' },
                { id: 'Processing', name: 'Processing' },
                { id: 'Closed', name: 'Closed' }
            ]} />
            <DisabledInput source="description" label="Konu" />
            <DisabledInput source="firstName" label="Ad" />
            <DisabledInput source="surname" label="Soyad" />
            <DisabledInput source="email" label="EMail" />
            <DisabledInput source="ccID" label="CID" />
        </SimpleForm>
    </Edit>
);

export const ReviewsList = props => (

    <List title="Kullanıcı Geribildirimleri" {...props}>
        <Datagrid redirect="list">
            <TextField source="status" label="Durum" />
            <TextField source="description" label="Konu" />
            <TextField source="firstName" label="Ad" />
            <TextField source="surname" label="Soyad" />
            <TextField source="email" label="EMail" />
            <TextField source="ccID" label="CID" />
            <DateField source="created" label="Bildirim Tarihi" />
            <DateField source="updated" label="Son Güncelleme Tarihi" />
            <EditButton label="" />
        </Datagrid>
    </List>
);