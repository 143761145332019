import React from "react";

import {
    List,
    Datagrid,
    TextField
} from 'react-admin';

export const InactiveUsersList = props => (
    <List title="Etkin Olmayan Kullanıcılar Listesi" {...props}>
        <Datagrid redirect="list">
            <TextField source="firstName" label="Adı" />
            <TextField source="surname" label="Soyadı" />
            <TextField source="ccID" label="CID" />
            <TextField source="emailAddress" label="E-Posta Adresi" />
            <TextField source="countryCode" label="Ülke Kodu" />
            <TextField source="phoneNr" label="Telefon Numarası" />
        </Datagrid>
    </List>
);
