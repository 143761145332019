import React from "react";
// import SaveButton from "./points/SaveButton";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  CreateButton,
  CardActions,
  Authenticated
} from 'react-admin';

const PointsCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="Save" redirect="list" submitOnEnter={false} />
  </Toolbar>
);

const ListActions = ({ basePath, data, resource }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

const PointsTitle = ({ record }) => {
  return <span>{record ? `${record.pointsCode}` : ""}</span>;
};

export const PointsEdit = props => (
  <Edit title={<PointsTitle />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="pointsCode" label="Puan Kodu" />
      <TextInput source="description" label="Puan Açıklaması" />
      <TextInput source="points" label="Puan Değeri" />
    </SimpleForm>
  </Edit>
);

export const PointsCreate = props => (
  <Create title="Puan Tanımlama" {...props}>
    <SimpleForm toolbar={<PointsCreateToolbar />} redirect="list">
      <TextInput source="pointsCode" label="Puan Kodu" />
      <TextInput source="description" label="Puan Açıklaması" />
      <TextInput source="points" label="Puan Değeri" />
    </SimpleForm>
  </Create>
);

export const PointsList = props => (
  <Authenticated>
    <List title="Puan Listesi" actions={<ListActions />} {...props}>
      <Datagrid redirect="list">
        <TextField source="pointsCode" label="Puan Kodu" />
        <TextField source="description" label="Puan Açıklaması" />
        <TextField source="points" label="Puan Değeri" />
        <DateField source="inserted" label="Tanımlanma Tarihi" />
        <EditButton label="" />
      </Datagrid>
    </List>
  </Authenticated>
);
