export default {
    /*
    s3: {
        REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
        BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    */
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://api.contactcircuit.com"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_jz5BhbrFa",
        APP_CLIENT_ID: "3qrf429h5u7er872cd07q50hj6",
        IDENTITY_POOL_ID: "us-east-1:5bb6700c-56e9-4d7c-a479-b48355655410"
    }
};