import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import { Auth, API } from "aws-amplify";

export default (type, params) => {
    return new Promise(async (resolve, reject) => {
        if (type === AUTH_LOGIN) {
            const { username, password } = params;
            let myuser = await Auth.signIn(username, password);
            let idToken = myuser.signInUserSession.idToken.jwtToken;

            let response = await API.post('ccapi', '/backoffice/login', {
                body: {},
                headers: { Authorization: idToken }
            });

            if (response.error) {
                throw new Error(response.error);
            } else {
                localStorage.setItem('token', idToken);
                localStorage.setItem('profile', JSON.stringify(response.profile));
                return resolve();
            }
        } else
            if (type === AUTH_LOGOUT) {
                localStorage.removeItem('token');
                localStorage.removeItem('profile');
                return resolve();
            } else if (type === AUTH_ERROR) {
                if (params.status === 401 || params.status === 403 || params.status === 500) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('profile');
                    return reject();
                }
                return resolve();
            }
            else if (type === AUTH_CHECK) {

                const localOK = localStorage.getItem('token');
                if (!localOK) return reject();
                else return resolve();
            }

            else return reject('Unkown method');
    });
}