import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  CardActions,
} from 'react-admin';

const ListActions = ({ basePath, data, resource }) => (
  <CardActions />
);


export const userList = props => (
  <List title="Yeni Kullanıcılar" bulkActions={false} actions={<ListActions />} {...props}>
    <Datagrid redirect="list" >
      <TextField source="firstName" label="Ad" />
      <TextField source="lastName" label="Soyad" />
      <TextField source="emailAddress" label="Email Adresi" />
      <TextField source="countryCode" label="Ülke Kodu" />
      <TextField source="phoneNr" label="Telefon Numarası" />
      <TextField source="ccID" label="CID" />
      <DateField source="inserted" label="Kayıt Tarihi" />
    </Datagrid>
  </List>
);
