import React from 'react';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive } from 'react-admin';
import UserIcon from '@material-ui/icons/Person';
import ReviewIcon from '@material-ui/icons/AddComment';
import LabelIcon from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';

const Menu = ({ resources, onMenuClick, logout }) => (
    <div>
        <MenuItemLink to="/points"
            primaryText="Puan Tanımlama"
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick} />
        <MenuItemLink to="/newusers" primaryText="Yeni Kullanıcılar" leftIcon={<UserIcon />} onClick={onMenuClick} />
        <MenuItemLink to="/inactiveusers" primaryText="Aktif Olmayan Kullanıcılar" leftIcon={<UserIcon />} onClick={onMenuClick} />
        <MenuItemLink to="/reviews" primaryText="Kullanıcı Geridönüşleri" leftIcon={<ReviewIcon />} onClick={onMenuClick} />
        <MenuItemLink to="/templates" primaryText="E-Posta/SMS Şablonları" leftIcon={<LabelIcon />} onClick={onMenuClick} />
        <Responsive xsmall={logout} medium={null} />
    </div>
);

const mapStateToProps = state => ({
    resources: getResources(state),
})
export default connect(mapStateToProps)(Menu);
