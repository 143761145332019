import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
// import jsonServerRestClient from 'ra-data-json-server';
import jsonServerRestClient from 'ra-data-json-server';

import { userList } from "./newusers";
import { PointsList, PointsEdit, PointsCreate } from "./points";
import { InactiveUsersList } from "./inactiveusers";
import { TemplatesList, TemplatesEdit, TemplatesCreate } from './templates';
import { ReviewsList, ReviewsEdit } from './reviews';

import Menu from './Menu';
import authProvider from './authProvider';
import Login from './Login';
import { Auth } from "aws-amplify";


const httpClient = async (url, options = {}) => {
  const authedUser = await Auth.currentAuthenticatedUser();
  let idToken = authedUser.signInUserSession.idToken.jwtToken;
  options.headers = new Headers({ Accept: 'application/json' });
  options.headers.append('Authorization', 'Bearer ' + idToken);
  /*
  options["credentials"] = "same-origin";
  options["redirect"] = "follow";
  options["referrer"] = "no-referrer";
  */
  return fetchUtils.fetchJson(url, options);

}

const restClient = jsonServerRestClient('https://api.contactcircuit.com/backoffice', httpClient);
// const restClient = jsonServerRestClient('', httpClient);

const App = () => (
  <Admin title="Contact Circuit Backoffice"
    locale="en"
    authProvider={authProvider}
    loginPage={Login}
    menu={Menu}
    dataProvider={restClient}>
    <Resource
      name="points"
      list={PointsList}
      edit={PointsEdit}
      create={PointsCreate}
    />
    <Resource
      name="newusers"
      list={userList}
    />
    <Resource
      name="inactiveusers"
      list={InactiveUsersList}
    />
    <Resource
      name="reviews"
      list={ReviewsList}
      edit={ReviewsEdit}
    />
    <Resource
      name="templates"
      list={TemplatesList}
      edit={TemplatesEdit}
      create={TemplatesCreate}
    />
  </Admin>
);

export default App;